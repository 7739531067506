import React from "react"

export const snippetsWidth = 300

interface ThemeInterface {
  background: string
  color: string
  highlight: string
  aceTheme: string
}

export interface EditorSize {
  width: number | string
  height: number | string
}

export const themes = {
  dark: {
    background: "#00242e",
    color: "#fff",
    highlight: "#8ed1ff",
    aceTheme: "solarized_dark",
  },
  light: {
    background: "#e8e8e8",
    color: "#364f6b",
    highlight: "#eb4f85",
    aceTheme: "tomorrow",
  },
}

export interface CpContextInterface {
  theme: ThemeInterface
  readOnly: boolean
  editorHeight: string
  modalState: boolean
  darkMode: boolean
  snippetsMode: boolean
  enableSnippets: boolean
  name: string
  htmlValue: string
  onToggleMode: (mode: string) => void
}

export const CpContext = React.createContext<CpContextInterface>(
  {} as CpContextInterface
)
