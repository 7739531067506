import {
  FormControl,
  FormLabel,
  Input,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Textarea,
  InputGroup,
  Select,
} from "@chakra-ui/react"
import { BlockFulfillmentEntity } from "@jackfruit/common"
import CodePreview from "components/CodePreview/CodePreview"
import ColorPickerInput from "forms/inputs/ColorPickerInput"
import React, { useCallback } from "react"
import { Controller, useFormContext } from "react-hook-form"

const nestedPrefix = "fulfillment"
const columnsPrefix = ["pickup", "delivery"]
const allStyles = ["Full-width", "Boxed"]
interface Props {
  readOnly?: boolean
  prefix?: boolean
  entity: BlockFulfillmentEntity
}

const BlockFulfillmentFields: React.FC<Props> = ({
  readOnly = false,
  prefix = true,
  entity,
}) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext()

  const setName = useCallback(
    (name: string) => {
      return prefix ? `${nestedPrefix}.${name}` : name
    },
    [prefix]
  )

  const ucfirst = useCallback((string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }, [])

  const styleOptions = allStyles.map(style => (
    <option key={style} value={style}>
      {style}
    </option>
  ))

  return (
    <>
      <FormControl isRequired>
        <FormLabel>Main title</FormLabel>
        <Input
          type="text"
          name="mainTitle"
          ref={register()}
          disabled={readOnly}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Layout Style</FormLabel>
        <InputGroup>
          <Select
            ref={register}
            name="fulfillmentStyle"
            placeholder="Select a layout"
            disabled={readOnly}
          >
            {styleOptions}
          </Select>
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel>Container background color</FormLabel>
        <Controller
          as={ColorPickerInput as any}
          name={setName("containerBgColor")}
          control={control}
          defaultValue={entity?.containerBgColor || "#272727"}
          disabled={readOnly}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Font color</FormLabel>
        <Controller
          as={ColorPickerInput as any}
          name={setName("fontColor")}
          control={control}
          defaultValue={entity?.fontColor || "#eee"}
          disabled={readOnly}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Check icon - Background color</FormLabel>
        <Controller
          as={ColorPickerInput as any}
          name={setName("checkBgColor")}
          control={control}
          defaultValue={entity?.checkBgColor || "#fd8181"}
          disabled={readOnly}
        />
      </FormControl>

      <Tabs
        borderWidth={1}
        borderStyle="solid"
        borderColor="#efefef"
        borderRadius={4}
      >
        <TabList>
          {columnsPrefix.map((prefix, index) => (
            <Tab key={index}>{ucfirst(prefix)} column</Tab>
          ))}
        </TabList>

        <TabPanels>
          {columnsPrefix.map((prefix, index) => (
            <TabPanel key={index}>
              <FormControl>
                <FormLabel>Title</FormLabel>
                <Input
                  type="text"
                  name={setName(`${prefix}Title`)}
                  ref={register()}
                  disabled={readOnly}
                />
              </FormControl>
              <FormControl marginTop={2}>
                <FormLabel>Description</FormLabel>
                <Textarea
                  height={10}
                  resize="vertical"
                  name={setName(`${prefix}Description`)}
                  ref={register()}
                  disabled={readOnly}
                />
              </FormControl>
              <FormControl isInvalid={errors[setName(`${prefix}SvgIcon`)]}>
                <FormLabel>Svg icon (optional)</FormLabel>
                <CodePreview
                  readOnly={readOnly}
                  name={setName(`${prefix}SvgIcon`)}
                />
              </FormControl>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  )
}

export default React.memo(BlockFulfillmentFields)
