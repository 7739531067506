import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react"
import { BlockSocialMediaEntity } from "@jackfruit/common"
import CodePreview from "components/CodePreview/CodePreview"
import FormContainer from "components/FormContainer"
import FormActions from "forms/FormActions"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"

interface Props {
  entity: BlockSocialMediaEntity
  isLoading: boolean
  onSubmit: (data: BlockSocialMediaEntity) => void
}

const BlockSocialMediaForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues = {
    ...entity,
  }

  const methods = useForm<BlockSocialMediaEntity>({
    defaultValues,
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <FormLabel>Title</FormLabel>
          <InputGroup>
            <Input type="text" name="title" ref={register()} />
          </InputGroup>
          <FormHelperText fontStyle="italic">
            Leave blank for default
          </FormHelperText>
        </FormControl>
        <FormControl isInvalid={Boolean(errors.body)}>
          <FormLabel>Body</FormLabel>
          <CodePreview name="body" />
          <FormHelperText fontStyle="italic">
            Leave blank for default. Useful shortcodes: [link
            field="supportUrl"], [link field="supportEmail"]
          </FormHelperText>
        </FormControl>
        <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
      </FormContainer>
    </FormProvider>
  )
}

export default BlockSocialMediaForm
