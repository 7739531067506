import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react"
import {
  BlockImageCarouselEntity,
  BlockImageGridEntity,
} from "@jackfruit/common"
import CodePreview from "components/CodePreview/CodePreview"
import FormContainer from "components/FormContainer"
import MultipleAssets from "components/MultipleAssets"
import FormActions from "forms/FormActions"
import ColorPickerInput from "forms/inputs/ColorPickerInput"
import React, { useCallback } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { FiAlignCenter, FiAlignLeft, FiAlignRight } from "react-icons/fi"
import { mergeStyle, removeKeys } from "utils/helpers"

interface Props {
  entity: BlockImageCarouselEntity & {
    [key: string]: any
  }
  isLoading: boolean
  onSubmit: (data: BlockImageCarouselEntity) => void
  params: Object
}

const devices = [
  {
    title: "Mobile",
    prefix: "Xs",
    defaultWidth: 50,
  },
  {
    title: "Tablet",
    prefix: "Md",
    defaultWidth: 30,
  },
  {
    title: "Desktop",
    prefix: "",
    defaultWidth: 20,
  },
]

const BlockImageCarouselForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const style = entity.style || {}

  const entityStyle = mergeStyle(style)

  const defaultValues = {
    ...entity,
    ...entityStyle,
  }

  const methods = useForm<BlockImageGridEntity>({
    defaultValues,
  })

  const { handleSubmit, register, control } = methods

  const enhancedOnSubmit = useCallback(
    data => {
      const assets = {
        assetsMobile: data.assetsMobile,
        assetsDesktop: data.assetsDesktop,
        assetsTablet: data.assetsTablet,
      }

      const style = {
        xs: {
          indicator: data.indicatorXs,
          indicatorColor: data.indicatorColorXs,
          indicatorFocusColor: data.indicatorFocusColorXs,
        },
        md: {
          indicator: data.indicatorMd,
          indicatorColor: data.indicatorColorMd,
          indicatorFocusColor: data.indicatorFocusColorMd,
        },
        lg: {
          indicator: data.indicator,
          indicatorColor: data.indicatorColor,
          indicatorFocusColor: data.indicatorFocusColor,
        },
      }

      const newData = removeKeys(data, [
        "assetsTablet",
        "assetsMobile",
        "assetsDesktop",
        "indicator",
        "indicatorXs",
        "indicatorMd",
        "indicatorColor",
        "indicatorColorXs",
        "indicatorColorMd",
        "indicatorFocusColor",
        "indicatorFocusColorXs",
        "indicatorFocusColorMd",
      ])

      const dataSet = {
        ...newData,
        assets,
        style,
      }

      onSubmit(removeKeys(dataSet, ["chosen", "selected"]))
    },
    [onSubmit]
  )

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(enhancedOnSubmit)}>
        <FormControl isRequired>
          <FormLabel>Content HTML</FormLabel>
          <CodePreview name="htmlConfig" />
        </FormControl>

        <FormControl>
          <FormLabel marginTop={4}>Delay</FormLabel>
          <NumberInput
            defaultValue={entity?.delay || 0}
            min={0}
            max={10000}
            step={1000}
            keepWithinRange={true}
            clampValueOnBlur={false}
          >
            <NumberInputField ref={register} name={"delay"} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>

        <Tabs
          border={1}
          borderColor="gray.100"
          borderStyle="solid"
          borderRadius={4}
        >
          <TabList>
            {devices.map((device, index) => (
              <Tab key={index}>{device.title}</Tab>
            ))}
          </TabList>

          <TabPanels>
            {devices.map((device, index) => (
              <TabPanel key={index}>
                <FormControl isRequired>
                  <FormLabel>Content HTML Alignment</FormLabel>

                  <Controller
                    name="alignment"
                    control={control}
                    render={({ onChange, value, name }) => {
                      return (
                        <HStack spacing={5} p={2}>
                          <Button
                            colorScheme="gray"
                            size="sm"
                            onClick={() => onChange(1)}
                            variant={
                              value === 1 || value === 0 ? "solid" : "outline"
                            }
                          >
                            {device.title === "Mobile" ? (
                              <FiAlignCenter />
                            ) : (
                              <FiAlignLeft />
                            )}
                          </Button>

                          <Button
                            colorScheme="gray"
                            size="sm"
                            onClick={() => onChange(2)}
                            variant={value === 2 ? "solid" : "outline"}
                          >
                            {device.title === "Mobile" ? (
                              <Box style={{ transform: "rotate(180deg)" }}>
                                <FiAlignCenter />
                              </Box>
                            ) : (
                              <FiAlignRight />
                            )}
                          </Button>
                        </HStack>
                      )
                    }}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel marginTop={4}>Indicator</FormLabel>

                  <Select name={`indicator${device.prefix}`} ref={register}>
                    <option value="dot">Dot</option>
                    <option value="thumbnail">Thumbnail</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel marginTop={4}>Indicator Color</FormLabel>

                  <Controller
                    as={ColorPickerInput as any}
                    name={`indicatorColor${device.prefix}`}
                    control={control}
                    placeholder="#ffffff"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel marginTop={4}>Indicator Focus Color</FormLabel>

                  <Controller
                    as={ColorPickerInput as any}
                    name={`indicatorFocusColor${device.prefix}`}
                    control={control}
                    placeholder="#6c6c6c"
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel marginTop={4}>Images {device.title}</FormLabel>
                  <Controller
                    as={MultipleAssets as any}
                    name={`assets${device.title}`}
                    defaultValue={entity[`assets${device.title}`]}
                    control={control}
                    showLink={false}
                  />
                </FormControl>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>

        <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
      </FormContainer>
    </FormProvider>
  )
}

export default BlockImageCarouselForm
