import { Checkbox, FormControl, FormLabel, InputGroup } from "@chakra-ui/react"
import { BlockHeaderEntity } from "@jackfruit/common"
import CodePreview from "components/CodePreview/CodePreview"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"

interface Props {
  entity: any
  isLoading: boolean
  onSubmit: (data: any) => void
}

const AppCommonHeaderSideWideBannerForm: React.FC<Props> = ({
  entity,
  isLoading,
  onSubmit,
}) => {
  const { header: dataBlockHeader }: { header: BlockHeaderEntity } = entity
  const defaultValues = {
    ...dataBlockHeader,
  }
  const methods = useForm({ defaultValues })
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormSectionHeader>Site-Wide Banner</FormSectionHeader>
        <FormControl>
          <InputGroup>
            <Checkbox
              ref={register}
              name="displaySiteWideBanner"
              defaultChecked={dataBlockHeader.displaySiteWideBanner}
            >
              Display Site-Wide Banner
            </Checkbox>
          </InputGroup>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.siteWideBannerContent)}>
          <FormLabel>Site-Wide Banner Content</FormLabel>
          <CodePreview name="siteWideBannerContent" />
        </FormControl>
        <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
      </FormContainer>
    </FormProvider>
  )
}
export default AppCommonHeaderSideWideBannerForm
