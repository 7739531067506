import { useClipboard } from "@chakra-ui/react"
import { Asset } from "../CpFileUpload"

export const useCustomCopy = (
  assetPath: Asset = {
    originalAssetPath: "",
    originalAssetType: "image/jpeg",
    originalFileSize: 0,
    webpAssetPath: "",
  }
) => {
  const snippet = `
  <picture>
  <source srcset=${assetPath?.webpAssetPath} type="image/webp">
  <source srcset=${assetPath?.originalAssetPath} type=${assetPath?.originalAssetType}>
  <img src=${assetPath?.originalAssetPath} alt="Description of the image">
</picture>`

  const { onCopy: onCopyOriginal } = useClipboard(assetPath?.originalAssetPath)

  const { onCopy: onCopySnippet } = useClipboard(snippet)

  const { onCopy: onCopyWebP } = useClipboard(assetPath?.webpAssetPath)

  return { onCopyOriginal, onCopySnippet, onCopyWebP }
}
