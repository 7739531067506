import { Container, FormErrorMessage, forwardRef } from "@chakra-ui/react"
import { useCallback, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { CpContext, themes } from "./CpContext"
import CpEditor from "./CpEditor"

export interface ObjectValue {
  html: string
  css: string
}

interface Props {
  name: string
  cssValue?: string
  cssIsGlobal?: boolean
  readOnly?: boolean
  editorHeight?: string
  enableSnippets?: boolean
}

const CodePreview = forwardRef<Props, "input">((props, ref) => {
  const {
    name,
    readOnly = false,
    editorHeight = "300px",
    enableSnippets = false,
  } = props

  const {
    watch,
    formState: { errors },
  } = useFormContext()

  const [modalState, setModalState] = useState(false)
  const [darkMode, setDarkMode] = useState(false)
  const [snippetsMode, setSnippetsMode] = useState(enableSnippets)
  const [theme, setTheme] = useState(themes.dark)

  useEffect(() => {
    setTheme(themes[darkMode ? "dark" : "light"])
  }, [darkMode])

  const onToggleMode = useCallback((mode: string) => {
    switch (mode) {
      case "snippets":
        setSnippetsMode(snippetsMode => !snippetsMode)
        break
      case "dark":
        setDarkMode(darkMode => !darkMode)
        break
      case "fullscreen":
        setModalState(modalState => !modalState)
        break
    }
  }, [])

  return (
    <>
      <CpContext.Provider
        value={{
          htmlValue: watch(name),
          onToggleMode,
          editorHeight,
          snippetsMode,
          enableSnippets,
          modalState,
          darkMode,
          readOnly,
          theme,
          name,
        }}
      >
        <Container
          id="previewContainer"
          position={modalState ? "fixed" : "relative"}
          left={0}
          top={0}
          height={modalState ? "100vh" : "100%"}
          width={modalState ? "100vw" : "100%"}
          padding={0}
          borderRadius={5}
          borderWidth={1}
          borderColor={theme.background}
          ref={ref}
          zIndex={modalState ? "modal" : "base"}
        >
          <CpEditor />
        </Container>
      </CpContext.Provider>
      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
    </>
  )
})

export default CodePreview
