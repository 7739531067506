import { Box } from "@chakra-ui/react"
import React from "react"
import BlockHtmlForm from "../forms/BlockHtmlForm"
import { useEntity } from "hooks/useEntity"
import { usePatchEntity } from "hooks/usePatchEntity"
import { BlockEntity, BlockHtmlEntity } from "@jackfruit/common"

interface Props {
  block: BlockEntity
}

const BlockHtml: React.FC<Props> = ({ block }) => {
  const { data: htmlBlock, isLoading } = useEntity<BlockHtmlEntity>(
    "blocks-html",
    block.childId
  )

  const { mutate: saveHtmlBlock, status: patchStatus } =
    usePatchEntity<BlockHtmlEntity>("blocks-html")

  const isPatching = patchStatus === "loading"

  const onSubmit = (data: any) => {
    saveHtmlBlock({ id: htmlBlock.id, ...data })
  }

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <BlockHtmlForm
      entity={htmlBlock}
      isLoading={isPatching}
      onSubmit={onSubmit}
    />
  )
}

export default BlockHtml
