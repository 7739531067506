import React from "react"
import { Box } from "@chakra-ui/react"
import { useHasOneResourceForm } from "hooks/useHasOneResourceForm"

import { BlockEntity } from "@jackfruit/common"
import BlockImageCarouselForm from "../forms/BlockImageCarouselForm"

interface Props {
  block: BlockEntity
}

const queryParams = {
  $eager: "[assetsMobile, assetsTablet, assetsDesktop]",
}

const BlockImageCarousel: React.FC<Props> = ({ block }) => {
  const { entity, isLoading, isCreating, isPatching, onSubmit } =
    useHasOneResourceForm<any>(
      "blocks-image-carousel",
      "blockId",
      block.id,
      queryParams
    )

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <BlockImageCarouselForm
      entity={entity}
      isLoading={isCreating || isPatching}
      onSubmit={onSubmit}
      params={queryParams}
    />
  )
}

export default BlockImageCarousel
