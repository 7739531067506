import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Image,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { useOptimizeImage, useUploadAsset } from "hooks/useUploadAsset"
import React, { useCallback, useRef, useState } from "react"
import { useDropzone } from "react-dropzone"
import { FiCopy, FiUpload } from "react-icons/fi"
import { truncatedContent } from "utils/helpers"
import { useCustomCopy } from "./hooks/useCustomCopy"

interface Props {}

export type ImageMimeType =
  | "image/jpeg"
  | "image/png"
  | "image/webp"
  | "image/gif"
  | "image/svg+xml"

export interface Asset {
  originalAssetPath: string
  originalAssetType: ImageMimeType
  originalFileSize: number
  webpAssetPath: string
}

interface CopyButtonProps extends ButtonProps {
  onCopy: () => void
  isDisabled: boolean
  label?: string
}

const CopyButton: React.FC<CopyButtonProps> = ({
  onCopy,
  isDisabled,
  label = "Copy",
  ...rest
}) => {
  return (
    <Button
      leftIcon={<FiCopy />}
      colorScheme="teal"
      variant="solid"
      h={4}
      p={2}
      fontSize="xs"
      onClick={() => onCopy()}
      disabled={isDisabled}
      {...rest}
    >
      {label}
    </Button>
  )
}

const CpFileUpload: React.FC<Props> = () => {
  const { upload } = useUploadAsset()
  const [isUploading, setIsUploading] = useState(false)
  const [filePath, setFilePath] = useState("")
  const [assetPath, setAssetPath] = useState<Asset>()
  const [errorMessage, setErrorMessage] = useState("")
  const { onCopyOriginal, onCopySnippet, onCopyWebP } = useCustomCopy(assetPath)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  const { optimizeImage } = useOptimizeImage(canvasRef)

  const onDrop = useCallback(
    async files => {
      try {
        const file: File = files[0]
        setIsUploading(true)

        let originalFile = file

        // Check if the file size exceeds 500KB
        if (file.size > 500 * 1024) {
          originalFile = await optimizeImage(file, file.type)
        }

        const webpFile = await optimizeImage(file, "image/webp")

        const [originalAsset, webpAsset] = await Promise.all([
          upload({ file: originalFile }),
          upload({ file: webpFile }),
        ])

        setFilePath(originalAsset.path)

        setAssetPath({
          originalAssetPath: originalAsset.path,
          originalAssetType: originalFile.type as ImageMimeType,
          originalFileSize: file.size,
          webpAssetPath: webpAsset.path,
        })

        setIsUploading(false)
        setErrorMessage("")
      } catch (error: any) {
        console.error(`File upload failed.`, error)
        setErrorMessage(error.message)
      }
    },
    [optimizeImage, upload]
  )

  const hasAssetPath = Boolean(assetPath)

  const showUploadedLand = !isUploading && hasAssetPath
  const showPrepareToUploadLand = !isUploading && !hasAssetPath

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "image/*",
    noClick: true,
    multiple: false,
    onDrop,
  })

  const tooltipText = errorMessage ? errorMessage : filePath ? filePath : ""

  const isDisabledCopyBtn = !assetPath

  return (
    <Box>
      <Box hidden>
        <canvas ref={canvasRef} />
      </Box>
      <Flex
        {...getRootProps()}
        w={"409px"}
        minW={0}
        bg="white"
        h={75}
        rounded="md"
      >
        <Box as="input" {...getInputProps()} />
        <Tooltip hasArrow label={tooltipText}>
          <Flex flexDir={"row"} w="full" p={2}>
            <Button
              onClick={open}
              flex="1"
              h="full"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={0}
              bg="white"
              overflow={"hidden"}
            >
              <Flex
                hidden={!showUploadedLand}
                flexDirection="row"
                alignItems="center"
                flex="1"
              >
                <Box w={24} mr={4} h={75}>
                  <Image
                    w={"auto"}
                    h="100%"
                    maxH={75}
                    objectFit="contain"
                    src={assetPath?.webpAssetPath}
                    margin="auto"
                  />
                </Box>

                <Box textAlign="left">
                  <Text noOfLines={1} mb={1} fontSize="xs">
                    {truncatedContent(assetPath?.originalAssetPath)}
                  </Text>
                  <Text noOfLines={1} mb={1} fontSize="xs">
                    {(Number(assetPath?.originalFileSize) / 1024).toFixed(2)} KB
                  </Text>
                  <Text noOfLines={1} fontSize="xs">
                    Uploaded
                  </Text>
                </Box>
              </Flex>

              <Flex
                hidden={!showPrepareToUploadLand}
                flexDirection="row"
                alignItems="center"
                flex="1"
              >
                <Box
                  w={24}
                  mr={4}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <FiUpload size={24} />
                </Box>

                <Box textAlign="left">
                  <Text noOfLines={1} mb={1} fontSize="xs">
                    No file uploaded
                  </Text>
                  <Text noOfLines={1} mb={1} fontSize="xs">
                    0.00KB
                  </Text>
                  <Text noOfLines={1} fontSize="xs">
                    Upload
                  </Text>
                </Box>
              </Flex>

              <Flex
                hidden={!isUploading}
                flexDirection="row"
                alignItems="center"
                flex="1"
              >
                <Box
                  w={24}
                  mr={4}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <FiUpload size={24} />
                </Box>

                <Box textAlign="left">
                  <Text noOfLines={1} mb={1} fontSize="xs">
                    Uploading...
                  </Text>
                </Box>
              </Flex>
            </Button>
            <Box w={24} h="full">
              <Box
                hidden={!showUploadedLand}
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
              >
                <CopyButton
                  onCopy={onCopyOriginal}
                  isDisabled={isDisabledCopyBtn}
                  label="Original"
                  mb={1}
                />

                <CopyButton
                  onCopy={onCopyWebP}
                  isDisabled={isDisabledCopyBtn}
                  label="Webp"
                  mb={1}
                />

                <CopyButton
                  onCopy={onCopySnippet}
                  isDisabled={isDisabledCopyBtn}
                  label="Snippet"
                />
              </Box>

              <Box
                hidden={!showPrepareToUploadLand}
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
              >
                <Button
                  leftIcon={<FiCopy />}
                  colorScheme="teal"
                  variant="solid"
                  h={4}
                  p={2}
                  fontSize="xs"
                  disabled
                >
                  Original
                </Button>
              </Box>
            </Box>
          </Flex>
        </Tooltip>
      </Flex>
    </Box>
  )
}

export default CpFileUpload
