import { AssetEntity } from "@jackfruit/common"
import { ImageMimeType } from "components/CodePreview/CpFileUpload"
import { useCallback } from "react"
import { useQueryClient } from "react-query"
import { getFileType } from "utils/helpers"
import api from "../services/api"

export const useUploadAsset = () => {
  const queryClient = useQueryClient()

  const upload = async ({ file }: { file: File }): Promise<AssetEntity> => {
    const assetEntity = await api.uploadOneFile(file)

    queryClient.invalidateQueries("assets")

    return assetEntity
  }

  return { upload }
}

// https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob

export const useOptimizeImage = (
  canvasRef: React.RefObject<HTMLCanvasElement>
) => {
  const optimizeImage = useCallback(
    (file: File, targetFileType, quality: number = 0.9) => {
      return new Promise<File>((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = event => {
          const imageType = getFileType(targetFileType as ImageMimeType)

          const img = new Image()
          img.src = event?.target?.result as string

          img.onload = () => {
            const canvas = canvasRef.current
            if (!canvas) {
              reject(new Error("Canvas not available."))
              return
            }

            const ctx = canvas.getContext("2d")
            canvas.width = img.width
            canvas.height = img.height
            ctx?.drawImage(img, 0, 0)

            canvas.toBlob(
              blob => {
                if (blob) {
                  const imageFile = new File(
                    [blob],
                    file.name.replace(/\.\w+$/, imageType),
                    {
                      type: targetFileType,
                    }
                  )
                  resolve(imageFile)
                } else {
                  reject(new Error("Optimize image failed."))
                }
              },
              targetFileType,
              quality
            )
          }

          img.onerror = () => reject(new Error("Image load failed."))
        }

        reader.onerror = () => reject(new Error("File read failed."))
        reader.readAsDataURL(file)
      })
    },
    [canvasRef]
  )

  return { optimizeImage }
}
