import { FormControl, FormLabel } from "@chakra-ui/react"
import { BlockHeaderEntity, NavigationEntity } from "@jackfruit/common"
import CodePreview from "components/CodePreview/CodePreview"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { forceNullable } from "utils/helpers"
import BlockHeaderFields from "./BlockHeaderFields"

interface Props {
  entity: BlockHeaderEntity
  navigations: NavigationEntity[]
  isLoading: boolean
  readOnly?: boolean
  onSubmit: (data: BlockHeaderEntity) => void
}

const BlockHeaderForm: React.FC<Props> = ({
  entity,
  navigations,
  onSubmit,
  isLoading,
  readOnly = false,
}) => {
  const defaultValues = {
    ...entity,
  }

  const methods = useForm<BlockHeaderEntity>({ defaultValues })
  const {
    handleSubmit,
    formState: { errors },
  } = methods

  const navigationOptions = navigations.map(navigation => (
    <option key={navigation.id} value={navigation.id}>
      {navigation.name}
    </option>
  ))

  const enhancedSubmit = (data: BlockHeaderEntity) => {
    const dataToSend = forceNullable(data, [
      "topNavigationId",
      "brandNavigationId",
      "mainNavigationId",
      "mobileNavigationId",
    ])
    onSubmit(dataToSend)
  }

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(enhancedSubmit)}>
        <FormSectionHeader>General</FormSectionHeader>
        <FormControl isInvalid={Boolean(errors.callToActionHtml)}>
          <FormLabel>Call To Action Button (Custom HTML)</FormLabel>
          <CodePreview name="callToActionHtml" />
        </FormControl>

        <BlockHeaderFields
          navigationOptions={navigationOptions}
          readOnly={readOnly}
          prefix={false}
        />

        <FormActions
          canCancel={false}
          isLoading={isLoading}
          entity={entity}
          readOnly={readOnly}
        />
      </FormContainer>
    </FormProvider>
  )
}

export default BlockHeaderForm
