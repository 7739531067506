import { Box, Flex } from "@chakra-ui/react"
import "ace-builds"
import { Ace } from "ace-builds"
import "ace-builds/src-noconflict/ext-language_tools"
import "ace-builds/webpack-resolver"
import { useWindowSize } from "hooks/useWindowSize"
import { Resizable } from "re-resizable"
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import AceEditor from "react-ace"
import { Controller, useFormContext } from "react-hook-form"
import {
  CpContext,
  CpContextInterface,
  EditorSize,
  snippetsWidth,
} from "./CpContext"
import CpHeader from "./CpHeader"
import CpResult from "./CpResult"

const setOptions = {
  enableBasicAutocompletion: true,
  enableLiveAutocompletion: true,
  autoScrollEditorIntoView: true,
  indentedSoftWrap: true,
  enableSnippets: true,
  wrap: true,
  tabSize: 2,
  fontSize: 11,
}

const editorProps = {
  $blockScrolling: false,
}

interface Props {
  value?: string
  editorSize?: EditorSize
}

const halfSnippetsWidth = snippetsWidth / 2

const CpEditor: React.FC<Props> = () => {
  const { theme, readOnly, editorHeight, modalState, name, snippetsMode } =
    useContext<CpContextInterface>(CpContext)

  const { control } = useFormContext()

  const { background, aceTheme } = theme
  const editorRef = useRef<any>(null!)

  const {
    windowSize: { width: windowWidth },
  } = useWindowSize()

  const [editorSize, setEditorSize] = useState<EditorSize>({
    width: windowWidth,
    height: "100%",
  })

  const resizableRef = useRef<any>(null!)
  const [resizableMaxWidth, setResizableMaxWidth] = useState(80)
  const [resizableMinWidth, setResizableMinWidth] = useState(20)
  const [isResizable, setIsResizable] = useState(true)

  useEffect(() => {
    if (modalState) {
      const editorMaxWidth = 80
      let editorWidth = windowWidth / 2

      if (snippetsMode) {
        editorWidth = editorWidth + halfSnippetsWidth
      }

      setEditorSize({
        width: editorWidth,
        height: "100%",
      })

      setResizableMaxWidth(editorMaxWidth)

      setIsResizable(true)
    } else {
      setResizableMaxWidth(100)
    }
  }, [modalState, snippetsMode, windowWidth])

  useEffect(() => {
    setResizableMinWidth(snippetsMode ? 30 : 20)
  }, [snippetsMode])

  const onResizeStop = useCallback(
    (e, direction, ref, d) => {
      setEditorSize({
        width: editorSize.width + d.width,
        height: "100%",
      })
    },
    [editorSize.width]
  )

  return (
    <Flex
      h={modalState ? "100vh" : "full"}
      w={modalState ? "100vw" : "full"}
      bg="white"
      zIndex={modalState ? "modal" : "base"}
    >
      <Resizable
        size={editorSize}
        maxWidth={
          modalState ? `${resizableMaxWidth}vw` : `${resizableMaxWidth}%`
        }
        minWidth={
          modalState ? `${resizableMinWidth}vw` : `${resizableMinWidth}%`
        }
        style={{
          position: "relative",
          borderRightWidth: modalState ? 2 : 0,
          borderColor: "gray",
          backgroundColor: "white",
        }}
        enable={{
          top: false,
          right: isResizable,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        ref={resizableRef}
        onResizeStop={onResizeStop}
      >
        <Flex direction="column" position="relative" p={0} w="full" h="full">
          <CpHeader />
          <Flex
            w="full"
            h="full"
            p={0}
            borderRight={1}
            borderRadius={0}
            position="relative"
            borderColor={background}
          >
            <Controller
              name={name}
              control={control}
              rules={{
                validate: () => {
                  const session = editorRef.current.editor.getSession()
                  const annotations =
                    session.getAnnotations() as Ace.Annotation[]

                  const errors = annotations.filter(
                    annotation => annotation.type === "error"
                  )

                  if (errors.length > 0) {
                    return errors[0].text
                  }

                  return true
                },
              }}
              render={({ onChange, value }) => {
                return (
                  <AceEditor
                    mode="html"
                    theme={aceTheme}
                    height={modalState ? "100%" : editorHeight}
                    width={modalState ? `${editorSize.width}px` : "100%"}
                    fontSize={13}
                    name={name}
                    value={value}
                    debounceChangePeriod={100}
                    onChange={onChange}
                    readOnly={readOnly}
                    setOptions={setOptions}
                    editorProps={editorProps}
                    ref={editorRef}
                  />
                )
              }}
            />
          </Flex>
        </Flex>
      </Resizable>
      {modalState && (
        <>
          <Box
            bg="gray"
            height="65px"
            maxWidth="2px"
            minWidth="2px"
            alignSelf="center"
          />
          <CpResult />
        </>
      )}
    </Flex>
  )
}

export default CpEditor
