import { FormControl } from "@chakra-ui/react"
import { BlockHtmlEntity } from "@jackfruit/common"
import "ace-builds"
import "ace-builds/webpack-resolver"
import CodePreview from "components/CodePreview/CodePreview"
import FormContainer from "components/FormContainer"
import FormActions from "forms/FormActions"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"

interface Props {
  entity: BlockHtmlEntity
  isLoading: boolean
  onSubmit: (data: BlockHtmlEntity) => void
}

const BlockHtmlForm: React.FC<Props> = ({ entity, onSubmit, isLoading }) => {
  const defaultValues = {
    ...entity,
  }

  const method = useForm<BlockHtmlEntity>({
    defaultValues,
  })

  const {
    handleSubmit,
    formState: { errors },
  } = method

  return (
    <FormProvider {...method}>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={Boolean(errors.html)}>
          <CodePreview name="html" />
        </FormControl>

        <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
      </FormContainer>
    </FormProvider>
  )
}

export default BlockHtmlForm
