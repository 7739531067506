import { FormControl, FormLabel, Icon, Text } from "@chakra-ui/react"
import { BlockFooterEntity } from "@jackfruit/common"
import CodePreview from "components/CodePreview/CodePreview"
import React from "react"
import { useFormContext } from "react-hook-form"
import { MdWarning } from "react-icons/md"

interface Props {
  colsLength: number
  rowIndex: number
  readOnly: boolean
  setName: (name: string) => string
}

const BlockFooterColumns: React.FC<Props> = ({
  colsLength,
  rowIndex,
  setName,
  readOnly,
}) => {
  const {
    formState: { errors },
  } = useFormContext()
  const colsDef = [...Array(colsLength)]

  return (
    <>
      {colsDef.map((_v, index) => {
        const colIndex = index + 1
        const fieldKey =
          `row${rowIndex}Col${colIndex}Html` as keyof BlockFooterEntity

        return (
          <FormControl
            marginBottom="10px"
            key={index}
            isInvalid={Boolean(errors[setName(fieldKey)])}
          >
            <FormLabel>Column {colIndex} - HTML code</FormLabel>

            {readOnly && (
              <Text
                fontSize="sm"
                marginTop={0}
                fontStyle="italic"
                marginBottom={3}
              >
                <Icon
                  as={MdWarning}
                  color="primary.500"
                  fontSize="sm"
                  marginRight={1}
                />
                Fields are read-only
              </Text>
            )}

            <CodePreview
              readOnly={readOnly}
              cssIsGlobal={true}
              name={setName(fieldKey)}
            />
          </FormControl>
        )
      })}
    </>
  )
}

export default BlockFooterColumns
