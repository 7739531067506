import {
  FormControl,
  FormErrorMessage,
  InputGroup,
  Checkbox,
  FormLabel,
  Input,
} from "@chakra-ui/react"
import React from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import FormActions from "forms/FormActions"
import FormSectionHeader from "components/FormSectionHeader"
import FormContainer from "components/FormContainer"
import { EntityFormProps } from "forms/EntityFormProps"
import { AppEntity } from "@jackfruit/common"
import AssetInput from "forms/inputs/AssetInput"
import CodePreview from "components/CodePreview/CodePreview"

interface Props extends EntityFormProps<AppEntity> {}

const AppBrandingDistributionAppOrderSummaryForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues = {
    ...entity,
  }

  const methods = useForm<AppEntity>({
    defaultValues,
  })

  const { handleSubmit, register, errors, control } = methods

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormSectionHeader>App-Install Block - Order Summary</FormSectionHeader>

        <FormControl
          isInvalid={Boolean(errors.blockOrderSummaryAppInstallEnabled)}
        >
          <InputGroup>
            <Checkbox
              ref={register}
              name="blockOrderSummaryAppInstallEnabled"
              defaultChecked={entity?.blockOrderSummaryAppInstallEnabled}
            >
              Show App-Install Block above Order Summary
            </Checkbox>
          </InputGroup>
          <FormErrorMessage>
            {errors.blockOrderSummaryAppInstallEnabled &&
              errors.blockOrderSummaryAppInstallEnabled.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.blockOrderSummaryAppInstallQrCode)}
        >
          <InputGroup>
            <Checkbox
              ref={register}
              name="blockOrderSummaryAppInstallQrCode"
              defaultChecked={entity?.blockOrderSummaryAppInstallQrCode}
            >
              Show QR Code
            </Checkbox>
          </InputGroup>
          <FormErrorMessage>
            {errors.blockOrderSummaryAppInstallQrCode &&
              errors.blockOrderSummaryAppInstallQrCode.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.blockOrderSummaryAppInstallQrCodeUrl)}
        >
          <FormLabel>App Store URL for QR Code</FormLabel>
          <InputGroup>
            <Input
              ref={register()}
              name="blockOrderSummaryAppInstallQrCodeUrl"
              type="text"
            />
          </InputGroup>
          <FormErrorMessage>
            {errors.blockOrderSummaryAppInstallQrCodeUrl &&
              errors.blockOrderSummaryAppInstallQrCodeUrl.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel>App Screen Image</FormLabel>
          <Controller
            as={AssetInput as any}
            name="blockOrderSummaryAppInstallAppScreenImageId"
            control={control}
          />
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.blockOrderSummaryAppInstallContent)}
        >
          <FormLabel>Title/Body Content</FormLabel>
          <CodePreview name="blockOrderSummaryAppInstallContent" />
        </FormControl>

        <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
      </FormContainer>
    </FormProvider>
  )
}

export default AppBrandingDistributionAppOrderSummaryForm
