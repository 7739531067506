import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react"
import { PageInteractionsEntity } from "@jackfruit/common"
import CodePreview from "components/CodePreview/CodePreview"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import { usePatchEntity } from "hooks/usePatchEntity"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"

interface Props {
  entity?: any
  isLoading: boolean
}

const AppPageFloatCtaDetailForm: React.FC<Props> = ({ entity, isLoading }) => {
  const { mutate: patchPageInteraction, status: patchStatus } =
    usePatchEntity<PageInteractionsEntity>("page-interactions")
  const isPatching = patchStatus === "loading"
  const defaultValues = entity

  const methods = useForm({ defaultValues })
  const { handleSubmit, register, errors } = methods

  const enhancedSubmit = (data: any) => {
    patchPageInteraction({
      id: entity.id,
      ...data,
    })
  }

  if (isLoading) {
    return null
  }

  return (
    <>
      <FormSectionHeader>Details</FormSectionHeader>

      <FormProvider {...methods}>
        <FormContainer onSubmit={handleSubmit(enhancedSubmit)} mt={4}>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <FormHelperText pb={4}>
              This is only visible to Builder users. It is hidden from front-end
              customers.
            </FormHelperText>
            <InputGroup>
              <Input ref={register} name="name" type="text" />
            </InputGroup>
            <FormErrorMessage>
              {errors.name && errors.name.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.htmlBody}>
            <FormLabel>HTML Body</FormLabel>
            <CodePreview name="htmlBody" />
            <FormErrorMessage>
              {errors.htmlBody && errors.htmlBody.message}
            </FormErrorMessage>
          </FormControl>

          <FormActions isLoading={isLoading || isPatching} entity={entity} />
        </FormContainer>
      </FormProvider>
    </>
  )
}

export default AppPageFloatCtaDetailForm
