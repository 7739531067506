import { FormControl, FormLabel } from "@chakra-ui/react"
import { BlockHtmlEntity, BlockImageUploadEntity } from "@jackfruit/common"
import "ace-builds"
import "ace-builds/webpack-resolver"
import CodePreview from "components/CodePreview/CodePreview"
import FormContainer from "components/FormContainer"
import FormActions from "forms/FormActions"
import React from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"

interface Props {
  entity: BlockImageUploadEntity
  isLoading: boolean
  onSubmit: (data: BlockHtmlEntity) => void
}

const BlockImageUploadForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues = {
    ...entity,
  }

  const methods = useForm<BlockImageUploadEntity>({
    defaultValues,
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={Boolean(errors.topHtml)}>
          <FormLabel>Top HTML Code</FormLabel>
          <Controller
            render={({ name, value, onChange }) => (
              <CodePreview name={name} value={value} onChange={onChange} />
            )}
            name="topHtml"
            control={control}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Bottom HTML Code</FormLabel>
          <CodePreview name="bottomHtml" />
        </FormControl>
        <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
      </FormContainer>
    </FormProvider>
  )
}

export default BlockImageUploadForm
