import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Select,
} from "@chakra-ui/react"
import {
  NavigationMegaMenuCellEntity,
  NavigationMegaMenuCellEntityHydrated,
} from "@jackfruit/common"
import CodePreview from "components/CodePreview/CodePreview"
import FormContainer from "components/FormContainer"
import { usePatchEntity } from "hooks/usePatchEntity"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useQueryClient } from "react-query"
import MegaMenuCellLinks from "./MegaMenuCellLinks"

interface Props extends Omit<ModalProps, "children"> {
  cell: NavigationMegaMenuCellEntityHydrated
}

const MegaMenuCellEditModal: React.FC<Props> = ({
  cell,
  onClose,
  ...modalProps
}) => {
  const queryClient = useQueryClient()
  const { mutateAsync: patchCell } =
    usePatchEntity<NavigationMegaMenuCellEntity>("navigation-megamenu-cells")

  const methods = useForm({
    defaultValues: cell,
  })

  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = methods

  const enhancedSubmit = async (data: NavigationMegaMenuCellEntity) => {
    await patchCell({
      id: cell.id,
      title: data.title,
      type: data.type,
      html: data.html,
    })
    queryClient.invalidateQueries(["navigation-megamenus"])
    reset({ title: data.title, type: data.type, html: data.html })
    onClose()
  }

  const type = watch("type")

  return (
    <Modal {...modalProps} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit cell</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormProvider {...methods}>
            <FormContainer onSubmit={handleSubmit(enhancedSubmit)}>
              <FormControl>
                <FormLabel>Title</FormLabel>
                <Input type="text" ref={register} name="title" />
              </FormControl>
              <FormControl>
                <FormLabel>Type</FormLabel>
                <InputGroup>
                  <Select ref={register} name="type">
                    <option value="navigation">navigation</option>
                    <option value="html">html</option>
                  </Select>
                </InputGroup>
              </FormControl>
              {type === "html" && (
                <FormControl isInvalid={Boolean(errors.html)}>
                  <CodePreview name="html" />
                </FormControl>
              )}
            </FormContainer>
          </FormProvider>
          {type === "navigation" && (
            <FormControl mt={4}>
              <FormLabel>Links</FormLabel>
              <MegaMenuCellLinks cell={cell} />
            </FormControl>
          )}
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="flex-end" width="full">
            <HStack>
              <Button onClick={onClose}>Cancel</Button>
              <Button colorScheme="blue" onClick={handleSubmit(enhancedSubmit)}>
                Save
              </Button>
            </HStack>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default MegaMenuCellEditModal
